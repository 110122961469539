body {
    width: 100%;
    height: 100%;
    padding: 0;
    background: #f4f7fa;
    color: #324c5b;
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: normal;
}

h1 {
    display: block; 
    font-size: 1.6em;
    background: white;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 10px;

    -webkit-box-shadow: 0 4px 4px 0 rgba(138, 138, 138, 0.2); 
    box-shadow: 0 4px 4px 0 rgba(128, 128, 128, 0.2); 

    z-index: +1;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    /* top distance from the window. Its has to be defined otherwise sticky won't work. */
    top: 0px;
}

h2 {
    display: block; 
    font-size: 1.2em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    margin-left: 10px;
}

h2 {
    color: teal;
}

h2, h3 {
    display: inline-block; 
    font-size: 1.5em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    padding-bottom: 5px;
}

h3{
    width: 50%;
    float: right;
    text-align: right;
}

h4 {
    display: block; 
    font-size: 1.6em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.CashList {

}
.CashList ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 15px 0 0;
    display: inline-block;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 10px;
}

.CashList ul li {
    display: inline-block;
    padding: 15px 15px 8px 10px;
    border-radius: 8px;
    background-color: white;
    text-align: left;
    -webkit-box-shadow: 0 4px 8px 0 rgba(48, 82, 120, .08);
    box-shadow: 0 4px 8px 0 rgba(48, 82, 120, .08);
    -webkit-transition: -webkit-box-shadow .2s ease-in-out;
    transition: -webkit-box-shadow .2s ease-in-out;
    -o-transition: box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out, -webkit-box-shadow .2s ease-in-out;
    margin-bottom: 10px;
    margin-right: 10px;
}

.CashList ul li :focus {
    background-color: #f14c52;
    color: #fff;
    -webkit-box-shadow: 0 4px 4px 0 rgba(241, 76, 82, .2); 
    box-shadow: 0 4px 4px 0 rgba(241, 76, 82, .2); 
}

.CashList button {
    position: relative;
    background: darkgrey;
    display: inline-block;
    height: 33px;
    line-height: 34px;
    width: 33px;
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 0 8px;
    border-radius: 4px;
    color: white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    border: none;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;

    -webkit-box-shadow: 0 4px 4px 0 rgba(138, 138, 138, 0.2); 
    box-shadow: 0 4px 4px 0 rgba(128, 128, 128, 0.2); 
}

.CashList button.button_red {
    background-color: #f14c52;
    color: #fff;
    -webkit-box-shadow: 0 4px 4px 0 rgba(241, 76, 82, .2); 
    box-shadow: 0 4px 4px 0 rgba(241, 76, 82, .2); 
}

@media only screen and (max-width: 768px) {
    .CashList button {
        height: 57px;
        width: 57px;
        margin-right: 7px;
        margin-bottom: 7px;
        font-size: 22px;
    }
  }

.CopyFormula {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
}

.CopyFormula :focus {
    background-color: #f14c52;
    color: #fff;
    -webkit-box-shadow: 0 4px 4px 0 rgba(241, 76, 82, .2); 
    box-shadow: 0 4px 4px 0 rgba(241, 76, 82, .2); 
}

.CopyFormula p {
    margin-left: 10px;
    margin-bottom: 10px;
    display: inline-block;
    background: honeydew;
    padding: 10px;
}
.CopyFormula button {
    display: block;
    width: 85px;
    height: 40px;
    margin-left: 10px;
    background: rgb(63, 63, 63);
}