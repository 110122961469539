
body {
  margin: 0;
  padding: 0;
  background: #f4f7fa;
  color: #324c5b;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
